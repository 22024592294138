/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    h3: "h3",
    ul: "ul",
    li: "li",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "스탠다드아웃 95번째 로그에서는 당근마켓 방구석 라이브, MS 오픈소스 사이트, 도커 리텐션 정책 등에 대해서 이야기를 나눴습니다."), "\n", React.createElement(_components.h3, null, "노션 한국어판 출시 후 개선 등"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.notion.so/What-s-New-157765353f2c4705bd45474e5ba8b46c"
  }, "What's New? - August 18, 2020")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://twitter.com/NotionHQ/status/1293705771817922564"
  }, "Notion on Twitter: \"Notion 한국어 버전 출시 후, 저희가 배운 첫 번째 교훈은 몸에 밴 습관은 쉽게 잊혀지지 않는다는 것입니다! \" / Twitter")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.atlassian.com/software/confluence"
  }, "Confluence | Your Remote-Friendly Team Workspace | Atlassian")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://bear.app/"
  }, "Private Markdown Notes for iPhone, iPad and Mac | Bear App")), "\n"), "\n", React.createElement(_components.h3, null, "당근마켓 방구석 라이브"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://festa.io/events/1146"
  }, "방구석 LIVE - 당근마켓 서버 개발/SRE 채용 오픈세션 | Festa!")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://festa.io/events/1185"
  }, "방구석 LIVE - 당근마켓 TS, Java, Go, Rails / SRE, ML 채용 오픈 세션 | Festa!"), " - 9월 23일 2차로 진행합니다!"), "\n"), "\n", React.createElement(_components.h3, null, "주피터 북"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://jupyterbook.org/intro.html"
  }, "Books with Jupyter")), "\n"), "\n", React.createElement(_components.h3, null, "테라폼 클라우드 비지니스 티어 출시"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://jupyterbook.org/intro.html"
  }, "Books with Jupyter"), "l"), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.okta.com/"
  }, "Okta | The Identity Standard")), "\n"), "\n", React.createElement(_components.h3, null, "MS 오픈소스 사이트 오픈"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://opensource.microsoft.com/"
  }, "Microsoft Open Source | Microsoft Open Source")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://github.com/microsoft/fluentui-system-icons"
  }, "microsoft/fluentui-system-icons: Fluent System Icons are a collection of familiar, friendly and modern icons from Microsoft.")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://dotnet.microsoft.com/"
  }, ".NET | Free. Cross-platform. Open Source.")), "\n"), "\n", React.createElement(_components.h3, null, "Scribd에서 Slideshare  인수"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.scribd.com/"
  }, "Discover the Best eBooks, Audiobooks, Magazines, Sheet Music, and More | Scribd")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://speakerdeck.com/"
  }, "Speaker Deck - Share Presentations without the Mess")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.oreilly.com/"
  }, "O'Reilly Media - Technology and Business Training")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.millie.co.kr/"
  }, "밀리의 서재")), "\n"), "\n", React.createElement(_components.h3, null, "모질라 정리해고"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.theverge.com/2020/8/11/21363424/mozilla-layoffs-quarter-staff-250-people-new-revenue-focus"
  }, "Mozilla is laying off 250 people and planning a ‘new focus’ on making money - The Verge")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://github.com/servo/servo/discussions/27577"
  }, "RIP Servo 2012-2020 · Discussion #27577 · servo/servo")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://civilityandtruth.com/2020/08/13/mozillas-uncertain-future/"
  }, "Mozilla’s uncertain future - Civility and Truth")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://mozillalifeboat.com/"
  }, "Mozilla Lifeboat")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://twitter.com/MozDevNet/status/1293647529268006912"
  }, "MDN Web Docs on Twitter: \"MDN as a website isn't going anywhere right now. ...\" / Twitter")), "\n"), "\n", React.createElement(_components.h3, null, "KubeCon + CloudNativeCon Europe Virtual 2020"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://events.linuxfoundation.org/kubecon-cloudnativecon-europe/"
  }, "KubeCon + CloudNativeCon Europe | Linux Foundation Events")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.redhat.com/en/about/press-releases/red-hat-and-intuit-join-forces-argo-project-extending-gitops-community-innovation-better-manage-multi-cluster-cloud-native-applications-scale"
  }, "Red Hat and Intuit Join Forces on Argo Project, Extending GitOps Community Innovation to Better Manage Multi-Cluster Cloud-Native Applications at Scale")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.cncf.io/blog/2020/08/19/toc-approves-thanos-from-sandbox-to-incubation/"
  }, "TOC approves Thanos from sandbox to incubation | Cloud Native Computing Foundation")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://github.com/lensapp/lens/issues/208"
  }, "Support MFA prompt for aws-iam-authenticator · Issue #208 · lensapp/lens")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://twitter.com/Outsideris/status/1295741975300157440"
  }, "Outsider on Twitter: \"온라인인데 세션 끝나고 다음 세션 선택해서 가는건 좀 자동으로 해주라. 장소 A에서 홀에 나왔다가 스케쥴 보고 장소 B로 이동하는 경험까지 똑같이 할 필요는 없는거 아닌가?\" / Twitter")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.cncf.io/about/members/"
  }, "Members | Cloud Native Computing Foundation")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://docs.docker.com/docker-hub/publish/certify-images/"
  }, "Certify Docker images | Docker Documentation")), "\n"), "\n", React.createElement(_components.h3, null, "도커(Docker) 리텐션 폴리시"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://pages-origin.hubtee.com/ko/post/news--docker-hub-image-retention-limit"
  }, "도커 허브(Docker Hub), 미사용 이미지 보존 기간 6개월로 제한 | 44BITS")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.docker.com/pricing/resource-consumption-updates"
  }, "Resource Consumption Updates FAQ | Docker")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://docs.docker.com/docker-hub/download-rate-limit/"
  }, "Download rate limit | Docker Documentation")), "\n"), "\n", React.createElement(_components.h3, null, "p5js 한글버전"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://medium.com/processing-foundation/p5-js-ko%EB%A5%BC-%EB%9F%B0%EC%B9%AD%ED%95%A9%EB%8B%88%EB%8B%A4-2f0affd2ff13"
  }, "p5.js/ko를 런칭합니다!. To read the English version of this… | by Processing Foundation | Processing Foundation | Aug, 2020 | Medium")), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
